import React, { useReducer } from 'react'
import styled from 'styled-components'
import 'antd/dist/antd.css'

import GlobalStyle from './globalStyle'

import './App.css'
import Root from 'pages'
import reducer from 'hooks/reducer'
import initState from 'hooks/state'
import Context from 'hooks/contextManage'

const StyledContainer = styled.div`
  padding-top: 60px;
`

function App() {
  const [state, dispatch] = useReducer(reducer, initState)

  return (
    <Context.Provider value={{ state, dispatch }}>
      <GlobalStyle />
      <StyledContainer>
        <Root />
      </StyledContainer>
    </Context.Provider>
  )
}

export default App

// reducer
export const INCIDENT = 'INCIDENT'
export const CORE_SERVICE = 'CORE_SERVICE'
export const DEV_PORTAL = 'DEV_PORTAL'
export const INCIDENTS_HISTORY = 'INCIDENTS_HISTORY'
export const UPTIME = 'UPTIME'
export const LATENCY = 'LATENCY'
export const INFRA_MEANTIME = 'INFRA_MEANTIME'
export const INFRA_BASIC = 'INFRA_BASIC'
export const MAINTENANCES = 'MAINTENANCES'

const reducer = (state, action) => {
  switch (action.type) {
    case INCIDENT:
      return { ...state, incident: action.data }
    case CORE_SERVICE:
      return { ...state, core: action.data }
    case DEV_PORTAL:
      return { ...state, devPortal: action.data }
    case INCIDENTS_HISTORY:
      return { ...state, pastIncidents: action.data }
    case LATENCY:
      return { ...state, latency: action.data }
    case INFRA_MEANTIME:
      return { ...state, meanTime: action.data }
    case INFRA_BASIC:
      return { ...state, projects: action.data }
    case UPTIME:
      return { ...state, uptime: action.data }
    case MAINTENANCES:
      return { ...state, maintenances: action.data }
    default:
      return state
  }
}

export default reducer

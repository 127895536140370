import React from 'react'
import PropTypes from 'prop-types'

const Down = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path d="M6 9L12 0L6 3L1.90735e-06 1.04907e-06L6 9Z" fill={fill} />
  </svg>
)

Down.defaultProps = {
  width: 12,
  height: 9,
  fill: '#00C259',
}
Down.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

export default Down

import React, { useState, useEffect, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { message } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import PastIncidents from 'components/Status/PastIncidents'
import { maxWidth, minWidth } from 'constants/options'
import Context from 'hooks/contextManage'
import { fetchIncidentsHistory } from 'hooks/action'
import * as api from 'api'

const StyledContainer = styled.div``

const StyledContent = styled.div`
  margin: 0 auto 30px;
  width: ${maxWidth}px;
  @media (min-width: ${minWidth}px) and (max-width: ${maxWidth + 20}px) {
    width: ${minWidth}px;
  }
  @media (max-width: ${minWidth + 20}px) {
    width: 100%;
    padding: 0 15px;
  }
`

const StyledBack = styled(Link)`
  font-size: 14px;
  color: #cccccc;
  line-height: 34px;
  width: 78px;
  height: 32px;
  background: #e9eef0;
  display: inline-block;
  text-align: center;
  color: #2a3b42;
`

function Pasts() {
  const { state, dispatch } = useContext(Context)
  const [loading, toggleLoading] = useState(false)

  const fetchIncidents = useCallback(
    async (pagesize = 15, page = 1) => {
      try {
        toggleLoading(true)
        const result = await api.fetchIncidentsHistory({
          currentItemLimit: pagesize,
          currentPageNumber: page,
        })
        toggleLoading(false)
        dispatch(fetchIncidentsHistory(result))
      } catch (e) {
        toggleLoading(false)
        message.error(e.message)
      }
    },
    [dispatch, toggleLoading],
  )

  useEffect(() => {
    fetchIncidents()
  }, [fetchIncidents])

  useEffect(() => {
    return () => {
      toggleLoading(false)
    }
  }, [])

  const fetchData = (page, pagesize) => {
    fetchIncidents(pagesize, page)
  }

  return (
    <StyledContainer>
      <StyledContent>
        <div style={{ marginTop: '20px' }}>
          <StyledBack to="/" data-auid="auid_status_back_btn">
            <LeftOutlined />
            <span style={{ marginLeft: '10px' }}>back</span>
          </StyledBack>
        </div>
        <PastIncidents
          data={state.pastIncidents}
          fetchData={fetchData}
          style={{ marginTop: '12px' }}
          loading={loading}
        />
      </StyledContent>
    </StyledContainer>
  )
}

Pasts.propTypes = {}

export default Pasts

import React from 'react'
import PropTypes from 'prop-types'

const DelIcon = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 120 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 0C20.8954 0 20 0.895431 20 2V4H12.1588C9.88854 4 8.0734 5.88724 8.16179 8.15573L10.8501 77.1557C10.9337 79.3027 12.6985 81 14.8471 81H105.153C107.301 81 109.066 79.3026 109.15 77.1557L111.838 8.15573C111.926 5.88723 110.111 4 107.841 4H34V2C34 0.895431 33.1046 0 32 0H22Z"
      fill="#DADADA"
    />
    <rect
      x="19.3745"
      y="9.00879"
      width="88"
      height="65"
      transform="rotate(4 19.3745 9.00879)"
      fill={fill}
    />
    <path
      d="M0.619153 22.5405C0.291646 20.1387 2.15853 18 4.58247 18H115.418C117.841 18 119.708 20.1387 119.381 22.5405L111.708 78.8107C111.302 81.7836 108.763 84 105.763 84H14.2374C11.2369 84 8.69776 81.7836 8.29237 78.8107L0.619153 22.5405Z"
      fill="#CDCDCD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.96 18L102.625 79.99L14.8398 73.8514L18.7454 18H106.96Z"
      fill="#F1F1F1"
    />
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.454 18L109.15 77.1557C109.066 79.3026 107.301 81 105.153 81H14.8471C12.6986 81 10.9338 79.3026 10.8502 77.1557L8.54541 18H111.454Z"
      fill="#B8B8B8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39 43.5C39 42.6716 39.6716 42 40.5 42H79.5C80.3284 42 81 42.6716 81 43.5C81 44.3284 80.3284 45 79.5 45H40.5C39.6716 45 39 44.3284 39 43.5ZM39.5 49.5C39.5 48.6716 40.1716 48 41 48H79C79.8284 48 80.5 48.6716 80.5 49.5C80.5 50.3284 79.8284 51 79 51H41C40.1716 51 39.5 50.3284 39.5 49.5ZM41.5 54C40.6716 54 40 54.6716 40 55.5C40 56.3284 40.6716 57 41.5 57H78.5C79.3284 57 80 56.3284 80 55.5C80 54.6716 79.3284 54 78.5 54H41.5Z"
      fill={fill}
    />
  </svg>
)

DelIcon.defaultProps = {
  width: '120',
  height: '84',
  fill: '#F0F0F0',
}
DelIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default DelIcon

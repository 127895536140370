import React, { useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { filter, addIndex, path } from 'ramda'

import { Bar } from 'components/Shared/Charts'
import Title from 'components/Shared/Title'
import { minWidth } from 'constants/options'
import Context from 'hooks/contextManage'

const StyledContainer = styled.div``

const StyledProjects = styled.div`
  padding: 20px 20px 5px;
  background: #fff;
  /*box-shadow: 0px 2px 5px rgba(42, 59, 66, 0.2);*/
  background: #f6f8f9;
  border-radius: 3px;
  @media (max-width: ${minWidth + 20}px) {
    padding: 14px 10px;
  }
`

function UptimeChart({ className, style }) {
  const { state } = useContext(Context)
  const data = path(['uptime'], state)

  return (
    <StyledContainer className={className} style={style}>
      <Title
        name={`Uptime: ${path(['serviceUpTime', 'uptimeRate'], data) || ''}`}
        rightText={path(['serviceUpTime', 'relativeTimeRange'], data)}
        testId="uptime"
      />

      <StyledProjects>
        <Bar
          data={addIndex(filter)(
            (item, idx) => idx < 30 && item,
            path(['serviceUpTime', 'uptimes'], data) || [],
          )}
        />
      </StyledProjects>
    </StyledContainer>
  )
}

UptimeChart.defaultProps = {
  style: {},
}

UptimeChart.propTypes = {
  // data: PropTypes.shape({
  //   serviceUpTime: PropTypes.shape({
  //     relativeTimeRange: PropTypes.string,
  //     uptimeRate: PropTypes.string,
  //     uptimes: PropTypes.arrayOf(
  //       PropTypes.shape({
  //         name: PropTypes.string,
  //         color: PropTypes.string,
  //       }),
  //     ),
  //   }),
  // }),
  style: PropTypes.shape({}),
}

export default UptimeChart

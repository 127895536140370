import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M8.99999 0.666504C4.39999 0.666504 0.666656 4.39984 0.666656 8.99984C0.666656 13.5998 4.39999 17.3332 8.99999 17.3332C13.6 17.3332 17.3333 13.5998 17.3333 8.99984C17.3333 4.39984 13.6 0.666504 8.99999 0.666504ZM9.83332 14.8332H8.16666V13.1665H9.83332V14.8332ZM11.5583 8.37484L10.8083 9.14151C10.2083 9.74984 9.83332 10.2498 9.83332 11.4998H8.16666V11.0832C8.16666 10.1665 8.54166 9.33317 9.14166 8.72484L10.175 7.67484C10.4833 7.37484 10.6667 6.95817 10.6667 6.49984C10.6667 5.58317 9.91666 4.83317 8.99999 4.83317C8.08332 4.83317 7.33332 5.58317 7.33332 6.49984H5.66666C5.66666 4.65817 7.15832 3.1665 8.99999 3.1665C10.8417 3.1665 12.3333 4.65817 12.3333 6.49984C12.3333 7.23317 12.0333 7.89984 11.5583 8.37484Z"
      fill="#A1B6BF"
    />
  </svg>
)

Logo.defaultProps = {
  width: '18',
  height: '18',
  fill: '#A1B6BF',
}
Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default Logo

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Modal, Button, Form, Input } from 'antd'
// import { any } from 'ramda'

import { LoadingImage } from 'components/Shared/common'
// import { regions } from 'constants/options'

const Styleddescription = styled.p`
  max-width: 380px;
  margin: 0 auto 30px;
  text-align: center;
  font-size: 16px;
`

const StyledButtons = styled.div`
  text-align: left;
  margin-top: 50px;
`

const layout = {
  labelCol: { span: 6, flex: '0 0 20%' },
  wrapperCol: { span: 16, flex: '0 0 70%' },
}

function SubscribeModal({ visible, loading, onCancel, onOk, onFinish }) {
  const footer = (
    <StyledButtons>
      <Button
        type="primary"
        data-auid="auid_btn_sub_submit"
        htmlType="submit"
        disabled={loading}
        style={{ marginRight: '10px' }}
      >
        <LoadingImage size="min" btnIcon spinning={loading} />
        Subcribe via Email
      </Button>
      <Button onClick={onCancel}>Cancel</Button>
    </StyledButtons>
  )

  //   const isCN = any(i => i.label === 'CN')(regions)

  const emailRules = [
    {
      required: true,
      message: 'Please input your email!',
    },
  ]

  // if (isCN) {
  emailRules.push({
    pattern: /^[a-zA-Z0-9_\\.-]+@+(hp\.com)+$/,
    message: 'The input can only put HP email',
  })
  //   } else {
  //     emailRules.push({
  //       type: 'email',
  //       message: 'The input is not valid email!',
  //     })
  //   }

  return (
    <Modal
      title="Subscribe the status notifications"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
    >
      <div>
        <Styleddescription data-auid="auid_sub_description">
          Get email notifications whenever creates, updates or resolves an incident
        </Styleddescription>
        <Form
          {...layout}
          name="control-hooks"
          labelAlign="right"
          onFinish={values => {
            onFinish(values)
          }}
        >
          <Form.Item name="email" label="Email" rules={emailRules}>
            <Input data-auid="auid_sub_input_email" />
          </Form.Item>
          <div>{footer}</div>
        </Form>
      </div>
    </Modal>
  )
}

SubscribeModal.defaultProps = {
  visible: false,
  loading: false,
  onFinish: () => {},
  onOk: () => {},
}

SubscribeModal.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  onFinish: PropTypes.func,
}

export default SubscribeModal

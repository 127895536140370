import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding-bottom: 25px;
  color: #697289;
  font-size: 16px;
  text-align: center;
`

function Footer() {
  const [year, setYear] = useState('')

  useEffect(() => {
    const date = new Date()
    setYear(date.getFullYear())
  }, [])

  return <Container>{`© HP Horizon ${year}`}</Container>
}

export default Footer

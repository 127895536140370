import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const skeletonLoading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`

const StyledStatusContent = styled.div`
  padding: 14px 20px;
  background: #e6edef;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 1px solid #dae2e5;
`

const StyledLoadingContent = styled.div`
  padding: 10px 20px;
  background: #f4f7f8;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, #f2f2f2),
    color-stop(37%, #e6e6e6),
    color-stop(63%, #f2f2f2)
  );
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ${skeletonLoading} 1.4s ease infinite;
  animation: ${skeletonLoading} 1.4s ease infinite;
`

function Container({ className, loading, children, style }) {
  return loading ? (
    <StyledLoadingContent />
  ) : (
    <StyledStatusContent className={className} style={style}>
      {children}
    </StyledStatusContent>
  )
}

Container.defaultProps = {
  loading: false,
}

Container.propTypes = { loading: PropTypes.bool }

export default Container

import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { path } from 'ramda'

import assetsLoading from 'assets/loading.png'

const SpinContianer = styled.div`
  text-align: center;
  margin: 60px 0;
  background: transparent;
`

const skeletonImageLoading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const loadingSize = {
  min: {
    iconWidth: '23px',
    iconHeight: '23px',
    paddingTop: '2px',
  },
  small: {
    width: '32px',
    height: '32px',
    iconWidth: '28px',
    iconHeight: '28px',
    paddingTop: '2px',
  },
  large: {
    width: '42px',
    height: '42px',
    iconWidth: '36px',
    iconHeight: '36px',
    paddingTop: '3px',
  },
}

const LoadingImageIcon = styled.img`
  animation: ${skeletonImageLoading} 1.1s linear infinite;
  width: ${props => (props.wh ? props.wh : '32px')};
  height: ${props => (props.wh ? props.wh : '32px')};
  vertical-align: top;
`

export const imgIndicator = (style = {}, size = 'large', testId, btnIcon) => {
  const imageNode = (
    <LoadingImageIcon
      src={assetsLoading}
      alt="loading"
      wh={path([size, 'iconWidth'], loadingSize)}
    />
  )
  return btnIcon ? (
    <span style={{ marginRight: '9px', lineHeight: '20px' }}>{imageNode}</span>
  ) : (
    <span
      style={{
        display: 'inline-block',
        textAlign: 'center',
        background: 'rgba(256,256,256,70%)',
        borderRadius: '8px',
        ...loadingSize[size],
        ...style,
      }}
      data-auid={`icon_loading${testId ? `_${testId}` : ''}`}
    >
      {imageNode}
    </span>
  )
}

export const LoadingImage = ({ children, style, size, isAlone, btnIcon, testId, ...resprops }) => {
  const SpinNode = (
    <Spin {...resprops} indicator={imgIndicator(style, size, testId, btnIcon)}>
      {children}
    </Spin>
  )
  const type = isAlone || btnIcon
  return <>{children || type ? SpinNode : <SpinContianer>{SpinNode}</SpinContianer>}</>
}

LoadingImage.defaultProps = {
  size: 'large',
  isAlone: false,
  testId: '',
  btnIcon: false,
}

LoadingImage.propTypes = {
  size: PropTypes.string,
  isAlone: PropTypes.bool,
  testId: PropTypes.string,
  btnIcon: PropTypes.bool,
}

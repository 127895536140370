import React from 'react'
import PropTypes from 'prop-types'

const Up = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path d="M6 -5.24537e-07L12 9L6 6L1.90735e-06 9L6 -5.24537e-07Z" fill={fill} />
  </svg>
)

Up.defaultProps = {
  width: 12,
  height: 9,
  fill: '#FF3333',
}

Up.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

export default Up

import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { map, find, propEq, filter, addIndex, path } from 'ramda'
import { Pagination, Tag } from 'antd'

import { LoadingImage } from 'components/Shared/common'
import Title from 'components/Shared/Title'
import RegionButton from 'components/Shared/RegionButton'
import { incident, minWidth, regions } from 'constants/options'
import Triangle from 'assets/Triangle'
import BgContainer from 'components/Shared/BgContainer'
import EmptyFolder from 'assets/EmptyFolder'
import Context from 'hooks/contextManage'

const StyledContainer = styled.div`
  margin-top: 20px;
  padding-bottom: 40px;
`

const StyledList = styled.div`
  overflow: hidden;
  position: relative;
  /*margin-bottom: 30px;*/
`

const StyledListHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${props => !props.collapse && `1px solid #dae2e5`};
  padding: 10px 3px;
  color: ${props => (props.color ? props.color : '#435761')};
  font-size: 15px;
  background: ${props => props.collapse && '#fdfbfb'};
  :hover {
    background: #fdfbfb;
    cursor: ${props => props.isViewAll && 'pointer'};
  }
  @media (max-width: ${minWidth + 20}px) {
    font-size: 14px;
  }
`

const StyledIncidentsContent = styled.div`
  background: #e6edef;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  border: 1px solid #dae2e5;
  border-top: ${props => props.color && `4px solid ${props.color}`};
`

const StyledHistoryTitle = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto 170px;
  @media (max-width: ${minWidth + 20}px) {
    grid-template-columns: auto 169px;
  }
`

const StyledHistoryTitleTime = styled.div`
  text-align: right;
  font-size: 14px;
  /* width: 151px;
  @media (max-width: ${minWidth + 20}px) {
    width: 132px;
  } */
`

const StyledIncidentsName = styled.div`
  border-bottom: 1px solid #dae2e5;
  font-size: 20px;
  font-weight: 600;
  padding: 14px 0 14px 20px;
`

const StyledIncidentList = styled.div`
  padding: 10px 0 10px 20px;
`

const StyledIncidentContent = styled.div`
  margin-top: 10px;
  padding-bottom: 8px;
`

const StyledIncidentStatus = styled.span`
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  margin-right: 10px;
  flex: 0.15;
`

const StyledIncidentCommit = styled.span`
  font-size: 14px;
  color: #435761;
  flex: 1;
  padding-right: 10px;
`

const StyledDate = styled.div`
  font-size: 12px;
  color: #748e99;
  margin-top: 2px;
`

const StyledPag = styled.div`
  text-align: center;
`

const Seal = styled.div`
  position: absolute;
  border: 2px solid #24f935;
  top: 27px;
  right: -7px;
  /*margin-left: -118px;*/
  padding: 0px 10px;
  font-size: 25px;
  color: #24f935;
  opacity: 0.15;
  font-family: 'BronxBystreets';
  transform: rotate(25deg);
`

const HistoryDetail = ({ findIncident, item, isViewAll }) => {
  const [collapse, setCollapse] = useState(false)
  const findRegion = find(propEq('value', item.region))(regions) || {}
  const regionsTag = (
    <Tag color="blue" style={{ marginLeft: '8px', verticalAlign: '1px' }}>
      {findRegion.label}
    </Tag>
  )
  return (
    <>
      <StyledListHeader
        isViewAll={isViewAll}
        collapse={collapse}
        color={!findIncident.resolveFlag ? findIncident.color : ''}
        onClick={() => isViewAll && setCollapse(i => !i)}
      >
        {isViewAll ? (
          <StyledHistoryTitle>
            <div style={{ paddingRight: '20px' }}>
              <Triangle
                style={{
                  transform: `rotate(${collapse ? 90 : 0}deg)`,
                  transition: '0.3s transform',
                  marginRight: '20px',
                  marginLeft: '5px',
                }}
              />
              {item.title}
              {regionsTag}
            </div>
            <StyledHistoryTitleTime>{`${item.timestamp} ${item.timezone}`}</StyledHistoryTitleTime>
          </StyledHistoryTitle>
        ) : (
          <>
            {`${findIncident.name} ${item.timestamp} ${item.timezone}`}
            {regionsTag}
          </>
        )}
      </StyledListHeader>
      {(collapse || !isViewAll) && (
        <StyledIncidentsContent color={findIncident.color}>
          <StyledIncidentsName>{findIncident.name}</StyledIncidentsName>
          <div>
            {map(
              i => (
                <StyledIncidentList key={i.id}>
                  <StyledIncidentContent>
                    <StyledIncidentStatus>{i.title}</StyledIncidentStatus>
                    <StyledIncidentCommit>{i.detailMsg}</StyledIncidentCommit>
                  </StyledIncidentContent>
                  <StyledDate>{`${i.timestamp} ${i.timezone}`}</StyledDate>
                </StyledIncidentList>
              ),
              item.incidentInfos,
            )}
          </div>
          {(collapse || !isViewAll) && item.resolveFlag && <Seal>Resolved</Seal>}
        </StyledIncidentsContent>
      )}
    </>
  )
}

HistoryDetail.propTypes = {
  findIncident: PropTypes.shape({
    name: PropTypes.string,
    timestamp: PropTypes.string,
    color: PropTypes.string,
    resolveFlag: PropTypes.bool,
  }).isRequired,
  item: PropTypes.shape({
    incidentInfos: PropTypes.arrayOf(PropTypes.shape({})),
    timestamp: PropTypes.string,
    timezone: PropTypes.string,
    level: PropTypes.string,
    title: PropTypes.string,
    resolveFlag: PropTypes.bool,
    region: PropTypes.string,
  }).isRequired,
  isViewAll: PropTypes.bool.isRequired,
}

function PastIncidents({
  className,
  style,
  isViewAll,
  showRegion,
  loading,
  setPastIncidentRegion,
  fetchData,
}) {
  const { state } = useContext(Context)
  const data = path(['pastIncidents'], state)

  const handleChange = (page, pageSize) => {
    fetchData(page, pageSize)
  }
  const totalItemCount = parseInt(path(['pagination', 'totalItemCount'], data) || 0, 10)
  const totalIncidents = isViewAll
    ? data.incidents
    : addIndex(filter)((i, idx) => idx < 1 && i, data.incidents || [])

  return (
    <StyledContainer className={className} style={style}>
      {showRegion && (
        <RegionButton
          onSwitch={region => {
            setPastIncidentRegion(region)
          }}
        />
      )}
      <div style={{ padding: `20px ${showRegion ? '20px' : 0} 0` }}>
        <Title name="Past Incidents" loading={!isViewAll && loading} testId="past_incidents" />
        {!data.incidents && <BgContainer loading style={{ borderTop: '4px solid #bfbfbf' }} />}
        {data.incidents && totalIncidents.length < 1 && (
          <div style={{ color: '#ddd', textAlign: 'center' }}>
            <EmptyFolder width="40" height="28" />
          </div>
        )}
        <LoadingImage spinning={isViewAll && loading} testId="history">
          {map(item => {
            const findIncident = find(propEq('level', item.level))(incident)
            const { resolveFlag } = item
            return (
              <StyledList key={item.id}>
                <HistoryDetail
                  findIncident={
                    resolveFlag ? { ...findIncident, color: '#748E99', resolveFlag } : findIncident
                  }
                  item={item}
                  isViewAll={isViewAll}
                />
              </StyledList>
            )
          }, totalIncidents || [])}
        </LoadingImage>
        <StyledPag>
          {isViewAll && totalItemCount > 0 && (
            <Pagination
              defaultCurrent={1}
              pageSize={15}
              total={totalItemCount}
              onChange={handleChange}
              onShowSizeChange={handleChange}
              style={{ marginTop: '20px' }}
            />
          )}
        </StyledPag>
      </div>
    </StyledContainer>
  )
}

PastIncidents.defaultProps = {
  style: {},
  isViewAll: true,
  fetchData: () => {},
  loading: false,
  showRegion: false,
  setPastIncidentRegion: () => {},
}

PastIncidents.propTypes = {
  // data: PropTypes.shape({
  //   pagination: PropTypes.shape({}),
  //   incidents: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       timestamp: PropTypes.string,
  //       name: PropTypes.string,
  //       color: PropTypes.string,
  //     }),
  //   ),
  // }).isRequired,
  style: PropTypes.shape({}),
  isViewAll: PropTypes.bool,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  showRegion: PropTypes.bool,
  setPastIncidentRegion: PropTypes.func,
}

export default PastIncidents

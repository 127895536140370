/* eslint-disable no-unused-vars */

import { requestCreator, requestOptionsCreator } from 'utils/apiHelper'

const apiBase = window.REACT_APP_API_BASE || process.env.REACT_APP_API_BASE

const token = ''
let request = {}

request = requestCreator(apiBase, requestOptionsCreator(token))

export const fetchIncidents = () => request({ url: `/v1/incidents/current` })

export const fetchIncidentsHistory = queryParams =>
  request({ url: `/v1/incidents/history`, queryParams })

export const fetchCoreService = queryParams => request({ url: `/v1/infra/core`, queryParams })

export const fetchDevPortal = () => request({ url: `/v1/infra/portal` })

export const fetchInfraBasic = queryParams => request({ url: `/v1/infra/basic`, queryParams })

export const fetchInfraMeantime = queryParams => request({ url: `/v1/infra/meantime`, queryParams })

export const fetchInfraUptime = queryParams => request({ url: `/v1/infra/uptime`, queryParams })

export const fetchLatency = queryParams => request({ url: `/v1/latency`, queryParams })

export const fetchMaintenances = () => request({ url: `/v1/maintenances` })

export const subscribe = queryParams =>
  request({
    url: `/v1/subscribe`,
    queryParams,
    method: 'POST',
  })

export const subscribeConfirm = queryParams =>
  request({ url: `/v1/subscribe/confirm`, queryParams })

export const unsubscribe = queryParams => request({ url: `/v1/unsubscribe`, queryParams })

const getDevice = () => {
  let name = ''
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    // iPhone|iPad|iPod|iOS
    name = 'mobile'
  } else if (/(Android)/i.test(navigator.userAgent)) {
    name = 'mobile'
    // Android
  } else {
    const isTouch = 'ontouchstart' in document
    name = isTouch ? 'mobile' : 'pc'
    // pc
  }
  return name
}

export default getDevice

import React from 'react'
import PropTypes from 'prop-types'

const Lock = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M3 4.5V3.02632C3 2.35629 3.26339 1.71372 3.73223 1.23994C4.20107 0.766165 4.83696 0.5 5.5 0.5C6.16304 0.5 6.79893 0.766165 7.26777 1.23994C7.73661 1.71372 8 2.3563 8 3.02632V4.5"
      stroke={fill}
    />
    <rect x="1" y="4" width="9" height="6" rx="1" fill={fill} />
  </svg>
)

Lock.defaultProps = {
  width: 10,
  height: 10,
  fill: '#C4C4C4',
}
Lock.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

export default Lock

import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { map, path } from 'ramda'

import { regions } from 'constants/options'

const StyledContainer = styled.div`
  line-height: 42px;
  border-bottom: 1px solid #dae2e5;
  background: #fff;
  padding: 15px 20px;
`

const StyledLeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledButton = styled.div`
  display: inline-block;
  width: 90px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border: ${props => (props.active ? '1px solid #229bff' : '1px solid #dae2e5')};
  cursor: pointer;
  color: ${props => (props.active ? '#229bff' : '#435761')};
  // background: ${props => (props.active ? '#229bff' : 'none')};
  transition: 0.5s all;
  :hover {
    color: ${props => (props.active ? '#0689f5' : '#0689f5')};
    transition: 0.5s all;
  }
`

const RegionBtns = ({ componentName, onSwitch }) => {
  const [region, setRegion] = useState(path([0, 'value'], regions))
  return (
    <StyledContainer data-auid={`auid_status_${componentName}_region`}>
      <StyledLeftContent>
        {map(
          i => (
            <StyledButton
              key={i.value}
              active={i.value === region}
              data-auid={`auid_status_${componentName}_region_${i.value}`}
              onClick={() => {
                setRegion(i.value)
                onSwitch(i.value)
              }}
            >
              {i.label}
            </StyledButton>
          ),
          regions,
        )}
      </StyledLeftContent>
    </StyledContainer>
  )
}

RegionBtns.defaultProps = {
  onSwitch: () => {},
  componentName: '',
}

RegionBtns.propTypes = {
  onSwitch: PropTypes.func,
  componentName: PropTypes.string,
}

export default RegionBtns

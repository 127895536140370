import React, { useEffect, useCallback, useState, useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { path, trim } from 'ramda'
import { Tooltip } from 'antd'

import Title from 'components/Shared/Title'
import CoreTable from 'components/Shared/CoreTable'
import UpIcon from 'assets/Up'
import DownIcon from 'assets/Down'
import Lock from 'assets/Lock'
import { minWidth } from 'constants/options'
import getDevice from 'utils/utils'
import Context from 'hooks/contextManage'

const StyledContainer = styled.div``

const columns = innerWidth => [
  {
    title: 'Service',
    dataIndex: 'name',
    key: 'name',
    width: '37%',
    render: (text, record, idx) => {
      return (
        <div data-auid={`auid_service_latency_name${idx}`}>
          <span
            style={{
              display: 'inline-block',
              width: getDevice() === 'mobile' && !record.privateFlag ? 0 : '19px',
            }}
          >
            {record.privateFlag && <Lock />}
          </span>
          <span style={{ color: !record.healthCheckFlag && `#FF3347` }}>{text}</span>
        </div>
      )
    },
  },
  {
    title: 'Latency',
    dataIndex: 'latency',
    key: 'latency',
    width: '33%',
    render: (text, record) => {
      return (
        <>
          <span style={{ display: 'inline-block', width: '19px' }}>
            {trim(record.historyLatencyFlag || '') === 'RED' && <UpIcon />}
            {trim(record.historyLatencyFlag || '') === 'GREEN' && <DownIcon />}
          </span>
          {record.historyLatency && record.historyLatency !== text ? (
            <Tooltip
              title={`Last month: ${record.historyLatency}`}
              placement={getDevice() === 'mobile' ? 'top' : 'right'}
            >
              <span>{text}</span>
            </Tooltip>
          ) : (
            <span>{text}</span>
          )}
        </>
      )
    },
  },
  {
    title: 'Success Rate',
    dataIndex: 'successRate',
    key: 'successRate',
    width: innerWidth < minWidth + 20 ? '40%' : '20%',
    render: (text, record) => {
      return (
        <>
          <span style={{ display: 'inline-block', width: '19px' }}>
            {trim(record.historySuccessRateFlag || '') === 'RED' && <DownIcon fill="#FF3333" />}
            {trim(record.historySuccessRateFlag || '') === 'GREEN' && <UpIcon fill="#00C259" />}
          </span>
          {record.historySuccessRate && record.historySuccessRate !== text ? (
            <Tooltip title={`Last month: ${record.historySuccessRate}`}>
              <span>{text}</span>
            </Tooltip>
          ) : (
            <span>{text}</span>
          )}
        </>
      )
    },
  },
]

function CoreServiceApis({ className, style, loading }) {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth || 0)

  const { state } = useContext(Context)
  const data = path(['latency'], state)

  const handleResize = useCallback(() => {
    setInnerWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    const o = 'orientationchange' in window ? 'orientationchange' : 'resize'
    window.addEventListener(o, handleResize)
    return () => {
      window.removeEventListener(o, handleResize)
    }
  }, [handleResize])

  return (
    <StyledContainer className={className} style={style}>
      <Title
        name="Core Services Latency"
        rightText={path(['relativeTimeRange'], data)}
        loading={loading}
        testId="latency"
      />
      {
        // <Table columns={columns} dataSource={dataSource} />
      }
      <CoreTable columns={columns(innerWidth)} dataSource={path(['children'], data) || []} />
    </StyledContainer>
  )
}

CoreServiceApis.defaultProps = {
  style: {},
  loading: false,
}

CoreServiceApis.propTypes = {
  // data: PropTypes.shape({
  //   relativeTimeRange: PropTypes.string,
  //   children: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       name: PropTypes.string,
  //       color: PropTypes.string,
  //     }),
  //   ),
  // }).isRequired,
  style: PropTypes.shape({}),
  loading: PropTypes.bool,
}

export default CoreServiceApis

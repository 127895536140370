import styled from 'styled-components'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import { maxWidth, minWidth } from 'constants/options'

export const StyledContainer = styled.div``

export const StyledContent = styled.div`
  margin: 30px auto 0;
  width: ${maxWidth}px;
  @media (min-width: ${minWidth}px) and (max-width: ${maxWidth + 20}px) {
    width: ${minWidth}px;
  }
  @media (max-width: ${minWidth + 20}px) {
    width: 100%;
    padding: 0 15px;
  }
`

export const StyledSub = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 140px;
  justify-content: end;
`

export const StyledButton = styled(Button)`
  width: 140px;
  height: 42px;
  font-size: 16px;
`

export const StyledMoreBtn = styled.div`
  text-align: center;
  padding-bottom: 30px;
`

export const StyledLink = styled(Link)`
  display: inline-block;
  width: 180px;
  height: 42px;
  line-height: 40px;
  background: #e9eef0;
  border: 1px solid #dae2e5;
  font-size: 16px;
  color: #2a3b42;
`

export const StyledRegions = styled.div`
  background: #fff;
  box-shadow: 0px 2px 5px rgba(42, 59, 66, 0.2);
`

/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map, join, addIndex, indexOf } from 'ramda'

import Triangle from 'assets/Triangle'
import EmptyFolder from 'assets/EmptyFolder'

const Container = styled.div``

const StyledHeader = styled.div``

const StyledHeaderRow = styled.div`
  background: #fff;
  box-shadow: 0px 2px 5px rgba(42, 59, 66, 0.2);
  border-radius: 3px;
  display: grid;
`

const StyledHeaderItems = styled.div`
  padding: 10px 2px 10px 30px;
  font-weight: 600;
  font-size: 14px;
  word-wrap: break-word;
`

const StyledBody = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  overflow-y: auto;
`

const StyledBodyRow = styled.div`
  display: grid;
  border-bottom: 1px solid #dae2e5;
  :hover {
    background: #fcfdfd;
  }
`

const StyledBodyChildRow = styled.div`
  display: grid;
  border-bottom: 1px solid #dae2e5;
  background: #e6edef;
  :hover {
    background: #fcfdfd;
  }
`

const StyledBodyItems = styled.div`
  padding: 10px;
  font-size: 14px;
  /*word-wrap: break-word;*/
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledColButton = styled.span`
  margin-right: 10px;
  cursor: pointer;
  transition: 0.5s transform;
`

const setWitdhValue = width =>
  indexOf('%', width) > -1
    ? `${parseInt(parseInt(width, 10) / 10, 10)}fr`
    : `${parseInt(width, 10)}px`

const TableBodyRow = ({ item, columns, lineNum, gridTemplateColumns }) => {
  const [isVisiableChild, toggleVisiableChild] = useState()
  return (
    <>
      <StyledBodyRow style={{ gridTemplateColumns }}>
        {addIndex(map)((column, idx) => {
          const content =
            column.render &&
            !(column.isHideHasChildren && item.children && item.children.length > 0)
              ? column.render(item[column.dataIndex], item, lineNum)
              : item[column.dataIndex]

          return (
            <StyledBodyItems key={column.dataIndex}>
              {idx === 0 && item.children && item.children.length > 0 && (
                <StyledColButton onClick={() => toggleVisiableChild(i => !i)}>
                  <Triangle
                    style={{
                      transform: `rotate(${isVisiableChild ? 90 : 0}deg)`,
                      transition: '0.3s transform',
                    }}
                  />
                </StyledColButton>
              )}
              {content}
            </StyledBodyItems>
          )
        }, columns)}
      </StyledBodyRow>
      {isVisiableChild &&
        addIndex(map)(
          (c, index) => (
            <StyledBodyChildRow style={{ gridTemplateColumns }} key={index}>
              {addIndex(map)((column, idx) => {
                const content = column.render
                  ? column.render(c[column.dataIndex], c)
                  : c[column.dataIndex]
                return (
                  <StyledBodyItems
                    style={{ paddingLeft: idx === 0 && '25px' }}
                    key={column.dataIndex}
                  >
                    {content}
                  </StyledBodyItems>
                )
              }, columns)}
            </StyledBodyChildRow>
          ),
          item.children || [],
        )}
    </>
  )
}

TableBodyRow.propTypes = {
  item: PropTypes.shape({
    email: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ).isRequired,
  lineNum: PropTypes.number.isRequired,
  gridTemplateColumns: PropTypes.string.isRequired,
}

const CoreTable = ({ dataSource, height, columns }) => {
  const bodyEl = useRef(null)

  const widths = map(
    i =>
      i.width
        ? setWitdhValue(i.width)
        : `minmax(${i.minWidth ? parseInt(i.minWidth, 10) : 20}px, 1fr)`, // `minmax(${parseInt(i.width, 0)}px, 1fr)` : '1fr'
    columns,
  )

  const gridTemplateColumns = join(' ', widths)

  return (
    <Container>
      <StyledHeader
        style={{ paddingRight: bodyEl.current && bodyEl.current.scrollHeight > height && '4px' }}
      >
        <StyledHeaderRow style={{ gridTemplateColumns }}>
          {map(
            column => (
              <StyledHeaderItems key={column.dataIndex}>{column.title}</StyledHeaderItems>
            ),
            columns,
          )}
        </StyledHeaderRow>
      </StyledHeader>
      <StyledBody style={{ height: `${(height * 10) / 10}px` }} ref={bodyEl}>
        {dataSource.length < 1 && (
          <div style={{ textAlign: 'center', paddingTop: '30px' }}>
            <EmptyFolder width="40" height="28" />
          </div>
        )}
        {addIndex(map)(
          (item, lineNum) => (
            <div key={item.id}>
              <TableBodyRow
                columns={columns}
                item={item}
                lineNum={lineNum}
                gridTemplateColumns={gridTemplateColumns}
              />
            </div>
          ),
          dataSource || [],
        )}
      </StyledBody>
    </Container>
  )
}

CoreTable.defaultProps = {
  pagination: { defaultCurrent: 1, total: 0, onChange: () => {}, onShowSizeChange: () => {} },
  height: 'auto',
}

CoreTable.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ).isRequired,
  pagination: PropTypes.shape({}),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default CoreTable

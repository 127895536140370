const uptimes = []
for (let i = 0; i < 30; i += 1) {
  uptimes.push({ datetime: null, id: i })
}

const initState = {
  incident: [],
  devPortal: { infraName: null, id: '2' },
  core: { infraName: null, id: '3' },
  projects: {
    basicInfra: [{ infraName: null, id: '1' }],
  },
  uptime: { serviceUpTime: { uptimeRate: null, uptimes } },
  meanTime: {
    meanTime: [
      { title: null, id: '1' },
      { title: null, id: '2' },
    ],
  },
  meanTimeBetween: null,
  latency: {},
  maintenance: [],
  pastIncidents: {},
}

export default initState

import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { filter, addIndex } from 'ramda'

import { minWidth, maxWidth } from 'constants/options'
import Tips from 'components/Shared/Tips'

const downLevel = time => {
  let level = 0
  let downtime = `0m`
  if (time > 0 && time <= 60) {
    level = 1
    downtime = `${parseInt(time, 10)}m`
  }
  if (time > 60 && time <= 240) {
    level = 2
    downtime = `>1h`
  }
  if (time > 240 && time <= 480) {
    level = 3
    downtime = `>4h`
  }
  if (time > 480) {
    level = 4
    downtime = `>8h`
  }
  return [level, downtime]
}

const Container = styled.div`
  margin-bottom: 10px;
`

const StyledItems = styled.div`
  width: ${props => props.width}px;
  height: 11rem;
`

const StyledItemsGray = styled.div`
  width: 100%;
  height: 11rem;
  background: #e0dede;
`

const StyledItemsGreen = styled.div`
  width: 100%;
  height: ${props => 11 * (1 - downLevel(props.value)[0] / 4)}rem;
  background: rgba(0, 194, 89, 0.4);
  border: ${props => (downLevel(props.value)[0] === 4 ? '0' : '1px solid #00c259')};
  margin-bottom: 1px;
`

const StyledItemsRed = styled.div`
  width: 100%;
  height: ${props => 11 * (downLevel(props.value)[0] / 4)}rem;
  background: rgba(255, 51, 71, 0.9);
`

const StyledTop = styled.div`
  display: flex;

  flex-direction: row-reverse;
  margin-bottom: 1rem;
`

const StyledBottom = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #435761;
`

const getWidth = () => window.document.documentElement.clientWidth || 0

const gap = (innerWidth, clientWidth) => {
  let num = 3.5
  if (innerWidth < maxWidth + 20 && innerWidth > minWidth) {
    num = 3.1
  }
  if (innerWidth < minWidth + 21) {
    num = (((clientWidth - 30 - 20 - 40) / (30 * 10)) * 2) / 2
  }

  return num
}

const barWidth = (innerWidth, clientWidth) => {
  let num = 18.3
  if (innerWidth < maxWidth + 20 && innerWidth > minWidth) {
    num = 18.08
  }
  if (innerWidth < minWidth + 21) {
    num = ((clientWidth - 30 - 20 - 40) / (30 * 10)) * 8
  }

  return num
}

function Bar({ data }) {
  const [clientWidth, setClientWidth] = useState(0)
  const [innerWidth, setInnerWidth] = useState(0)
  const [block, selectedBlock] = useState(null)

  const handleBlock = useCallback(() => {
    selectedBlock(null)
  }, [selectedBlock])

  const handleResize = useCallback(() => {
    setClientWidth(getWidth())
    setInnerWidth(window.innerWidth)
    selectedBlock(null)
  }, [])

  useEffect(() => {
    setClientWidth(getWidth())
    setInnerWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    const o = 'orientationchange' in window ? 'orientationchange' : 'resize'
    window.addEventListener(o, handleResize)
    return () => {
      window.removeEventListener(o, handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    document.addEventListener('scroll', handleBlock)
    return () => {
      document.removeEventListener('scroll', handleBlock)
    }
  }, [handleBlock])

  useEffect(() => {
    document.addEventListener('click', handleBlock)
    return () => {
      document.removeEventListener('click', handleBlock)
    }
  }, [handleBlock])

  const startDate = addIndex(filter)((i, idx) => idx === 29, data)

  return (
    <Container>
      <StyledTop>
        {data.map((i, index) => {
          const m = i.totalDowntime / 60
          const verticalLine = (
            <StyledItems width={barWidth(innerWidth, clientWidth)}>
              {i.datetime ? (
                <>
                  <StyledItemsGreen value={m} />
                  <StyledItemsRed value={m} />
                </>
              ) : (
                <StyledItemsGray />
              )}
            </StyledItems>
          )
          const tipsContent = (
            <>
              <div>{i && i.datetime}</div>
              <div>{`Downtime: ${downLevel(m)[1]}`}</div>
            </>
          )

          return (
            <Tips
              content={tipsContent}
              block={i.datetime && block}
              index={index}
              selectedBlock={selectedBlock}
              key={i.id}
              placement="top"
            >
              <div
                style={{
                  margin: `0 ${gap(innerWidth, clientWidth)}px`,
                }}
              >
                {verticalLine}
              </div>
            </Tips>
          )
        })}
      </StyledTop>
      <StyledBottom>
        <div>{startDate.length > 0 ? startDate[0].datetime : 'Start'}</div>
        <div>Today</div>
      </StyledBottom>
    </Container>
  )
}

Bar.defaultProps = {
  data: [],
}

Bar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
}

export default Bar

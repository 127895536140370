import React from 'react'
import PropTypes from 'prop-types'

const Success = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM5.51472 13.5375L6.92899 12.1232L9.41239 14.6066C9.80291 14.9971 10.4361 14.9971 10.8266 14.6066L17.1905 8.24269L18.6047 9.65689L12.2407 16.0208C11.0692 17.1924 9.16967 17.1924 7.9981 16.0208L5.51472 13.5375Z"
      fill="#00C259"
    />
  </svg>
)

Success.defaultProps = {
  width: 20,
  height: 20,
  fill: '#00C259',
}
Success.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

export default Success

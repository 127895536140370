import React from 'react'
import PropTypes from 'prop-types'

const Failed = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <circle cx="12" cy="12" r="12" fill="#FF3347" />
    <circle cx="12" cy="18" r="2" fill="white" />
    <path
      d="M10.199 5.99008C10.0925 4.92457 10.9292 4 12 4C13.0708 4 13.9075 4.92457 13.801 5.99007L13.0995 13.005C13.043 13.5698 12.5677 14 12 14C11.4323 14 10.957 13.5698 10.9005 13.005L10.199 5.99008Z"
      fill="white"
    />
  </svg>
)

Failed.defaultProps = {
  width: 20,
  height: 20,
  fill: '#FF3347',
}
Failed.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

export default Failed

import React, { useContext } from 'react'
import styled from 'styled-components'
// import PropTypes from 'prop-types'
import { map, path, split, join, toLower } from 'ramda'

import Context from 'hooks/contextManage'
import Title from 'components/Shared/Title'
import RegionButton from 'components/Shared/RegionButton'
import BgContainer from 'components/Shared/BgContainer'
import Success from 'assets/Success'
import Failed from 'assets/Failed'

const StyledContainer = styled.div``

const StyledProjects = styled.div`
  margin-bottom: 20px;
  padding: 15px 20px;
`

const StyledDescription = styled.span`
  display: inline-block;
  width: 100%;
  margin-left: 12px;
  font-size: 16px;
  color: #182429;
`

function ViewStatus() {
  const { state } = useContext(Context)
  const data = path(['devPortal'], state) || []
  return (
    <StyledContainer>
      {map(
        item => (
          <div
            style={{ background: '#fff', boxShadow: '0px 2px 5px rgba(42, 59, 66, 0.2)' }}
            key={item.id || item.infraName}
          >
            {item.infraName === 'Core Service' && (
              <RegionButton
                onSwitch={region => {
                  console.log(region)
                }}
              />
            )}
            <StyledProjects>
              {item.infraName && (
                <Title
                  name={item.infraName}
                  visiableHelp={item.helpIconFlag}
                  tips={item.helpInfo}
                  testId={toLower(join('_', split(' ', item.infraName)))}
                />
              )}
              <BgContainer loading={!item.infraName}>
                {item.infraName && (
                  <>
                    {item.statusFlag ? <Success /> : <Failed />}
                    <StyledDescription>{item.infraMessage}</StyledDescription>
                  </>
                )}
              </BgContainer>
            </StyledProjects>
          </div>
        ),
        path(['basicInfra'], data) || [],
      )}
    </StyledContainer>
  )
}

ViewStatus.defaultProps = {}

ViewStatus.propTypes = {
  // data: PropTypes.shape({
  //   basicInfra: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       name: PropTypes.string,
  //       color: PropTypes.string,
  //     }),
  //   ),
  // }).isRequired,
}

export default ViewStatus

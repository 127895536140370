import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { LoadingImage } from 'components/Shared/common'
import Question from 'assets/Question'
import { minWidth } from 'constants/options'
import getDevice from 'utils/utils'

const StyledHeader = styled.div`
  line-height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: ${minWidth + 20}px) {
    display: block;
    line-height: 26px;
  }
`

const StyledName = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #435761;
  @media (max-width: ${minWidth + 20}px) {
    font-size: 18px;
  }
`

const StyledRightContent = styled.span`
  font-size: 14px;
  color: #748e99;
  @media (max-width: ${minWidth + 20}px) {
    font-size: 12px;
  }
`

const StyledLeftContent = styled.div`
  display: flex;
  align-items: center;
`

function Title({ name, testId, visiableHelp, tips, rightText, loading }) {
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />
  return (
    <StyledHeader>
      <StyledLeftContent>
        <StyledName data-auid={`auid_status_${testId}`}>{name}</StyledName>
        {visiableHelp && (
          <Tooltip title={tips} trigger={getDevice() === 'pc' ? 'hover' : 'click'}>
            <Question style={{ marginLeft: '10px', cursor: 'pointer' }} />
          </Tooltip>
        )}
        <LoadingImage
          indicator={antIcon}
          style={{ marginLeft: '10px', marginTop: '5px' }}
          spinning={loading}
          testId={testId}
          size="small"
          isAlone
        />
      </StyledLeftContent>
      {rightText && <StyledRightContent>{rightText}</StyledRightContent>}
    </StyledHeader>
  )
}

Title.defaultProps = {
  name: '',
  tips: '',
  rightText: '',
  visiableHelp: false,
  loading: false,
  testId: '',
}

Title.propTypes = {
  name: PropTypes.string,
  visiableHelp: PropTypes.bool,
  tips: PropTypes.string,
  rightText: PropTypes.string,
  loading: PropTypes.bool,
  testId: PropTypes.string,
}

export default Title

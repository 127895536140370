import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 116 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <g filter="url(#filter0_f)">
      <path
        d="M37 20.5C37 29.6127 29.6127 37 20.5 37C11.3873 37 4 29.6127 4 20.5C4 11.3873 11.3873 4 20.5 4C29.6127 4 37 11.3873 37 20.5Z"
        fill="url(#paint0_angular)"
      />
    </g>
    <g filter="url(#filter1_f)">
      <circle cx="21" cy="21" r="16" fill="url(#paint1_angular)" />
    </g>
    <g filter="url(#filter2_f)">
      <circle cx="21" cy="21" r="15" fill="url(#paint2_angular)" />
    </g>
    <g filter="url(#filter3_f)">
      <circle cx="23" cy="23" r="16" fill="#2E2E2E" />
    </g>
    <path d="M67 16.6665L69 15.6665V29.9998H67V16.6665Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.5826 16.1608C42.6423 15.7363 41.5987 15.5 40.5 15.5C36.3579 15.5 33 18.8579 33 23C33 25.5068 34.2298 27.7263 36.119 29.0881L37.1261 27.3439C35.8323 26.3376 35 24.766 35 23C35 19.9624 37.4624 17.5 40.5 17.5C41.2343 17.5 41.9351 17.6439 42.5755 17.9051L43.5826 16.1608ZM38.4245 28.0949C39.0649 28.3561 39.7657 28.5 40.5 28.5C43.5376 28.5 46 26.0376 46 23C46 21.234 45.1677 19.6625 43.874 18.6561L44.881 16.9119C46.7702 18.2737 48 20.4933 48 23C48 27.1421 44.6421 30.5 40.5 30.5C39.4013 30.5 38.3577 30.2637 37.4174 29.8392L38.4245 28.0949Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.5825 16.1607C95.6422 15.7362 94.5987 15.5 93.5 15.5C89.3579 15.5 86 18.8579 86 23C86 25.5067 87.2298 27.7262 89.1189 29.088L90.1259 27.3439C88.8321 26.3376 87.9998 24.766 87.9998 23C87.9998 19.9624 90.4623 17.5 93.4998 17.5C94.2342 17.5 94.9349 17.6439 95.5754 17.9051L96.5825 16.1607ZM91.4243 28.0949C92.0648 28.3561 92.7655 28.5 93.4998 28.5C96.5374 28.5 98.9998 26.0376 98.9998 23C98.9998 21.234 98.1675 19.6625 96.8738 18.6561L97.8809 16.9118C99.7701 18.2736 101 20.4932 101 23C101 27.1421 97.6421 30.5 93.5 30.5C92.4012 30.5 91.3576 30.2637 90.4173 29.8392L91.4243 28.0949Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104 16.6665L106 15.6665V29.9998H104V16.6665ZM114 16.6665L116 15.6665V29.9999H115.333H114H113.5L107 19.3332V15.6665L114 27.025V16.6665Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 16.6665L20 15.6665V29.9998H18V16.6665ZM28 16.6665L30 15.6665V23.9996L30.0003 23.9998H30V29.9999H28V23.9998H21.0002V21.9998H28V16.6665Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.3211 16H73V18H79.8925L81.3211 16ZM82.5001 16.0001H84.5V16.7003L76.4288 28H85.0002V30H75.0002L75.0001 30.0001H73V29.3003L82.5001 16.0001Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51 17L53 16V30H51V17ZM58.5 25C60.9853 25 63 22.9853 63 20.5C63 18.0705 61.0747 16.0907 58.6667 16.003V16H58.5H54.0002V18H58.5C59.8807 18 61 19.1193 61 20.5C61 21.8807 59.8807 23 58.5 23H56.5H54L60.6667 30H63.3333L58.4524 25H58.5Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_f"
        x="0"
        y="0"
        width="41"
        height="41"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter1_f"
        x="3"
        y="3"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter2_f"
        x="4.5"
        y="4.5"
        width="33"
        height="33"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.75" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter3_f"
        x="6"
        y="6"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur" />
      </filter>
      <radialGradient
        id="paint0_angular"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.5 20.5) rotate(90) scale(16.5)"
      >
        <stop offset="0.0989583" stopColor="#6666FF" />
        <stop offset="0.354167" stopColor="#5883FF" />
        <stop offset="0.604167" stopColor="#5AD3FF" />
      </radialGradient>
      <radialGradient
        id="paint1_angular"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21 21) rotate(90) scale(16)"
      >
        <stop offset="0.0989583" stopColor="#6666FF" />
        <stop offset="0.354167" stopColor="#5883FF" />
        <stop offset="0.604167" stopColor="#5AD3FF" />
      </radialGradient>
      <radialGradient
        id="paint2_angular"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21 21) rotate(90) scale(15)"
      >
        <stop offset="0.148623" stopColor="#9A9AFF" />
        <stop offset="0.354167" stopColor="#FFF9D8" />
        <stop offset="0.549685" stopColor="#98E4FF" />
      </radialGradient>
    </defs>
  </svg>
)

Logo.defaultProps = {
  width: '116',
  height: '41',
  fill: '#617F8C',
}
Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default Logo

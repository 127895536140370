import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family:  'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#F6F8F9;
}

ul,
li {
  list-style: none;
}

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input, button, select, optgroup, textarea {
  font-family: Roboto,Helvetica neue,Avenir,Arial;
}



`

export default GlobalStyle

import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import getDevice from 'utils/utils'

const tipsWidth = 150

const StyledContainer = styled.div``

const StyledEvent = styled.div``

const StyledTooltip = styled.div`
  padding: 10px;
  width: ${tipsWidth}px;
  background: #2a3b42;
  position: fixed;
  border-radius: 3px;
  z-index: 999;
  color: #fff;
  ::before {
    content: '';
    position: absolute;
    left: ${props => props.beforeleft || 68}px;
    bottom: ${props => props.placement === 'top' && '-14px'};
    top: ${props => props.placement === 'bottom' && '-14px'};
    width: 0;
    height: 0;
    border-top: 7px solid ${props => (props.placement === 'top' ? '#2a3b42' : 'transparent')};
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${props => (props.placement === 'bottom' ? '#2a3b42' : 'transparent')};
  }
`

const tipsLeft = position => {
  let tipsX = position.x - tipsWidth / 2 + position.width / 2
  let iconX = 68
  if (position.x < 100) {
    tipsX = position.x - 10
    iconX = position.width / 2 - 7 + 10
  }
  if (position.winWidth - position.x < 100) {
    tipsX = position.x - (tipsWidth - position.width - 10)
    iconX = tipsWidth - (position.width / 2 + 10 + 7)
  }
  return [tipsX, iconX]
}

function Tips({
  trigger,
  content,
  index,
  block,
  children,
  selectedBlock,
  placement,
  ...restProps
}) {
  const textRef = useRef()
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    width: 0,
  })

  const onHover = e => {
    e.nativeEvent.stopImmediatePropagation()
    selectedBlock(index)
    const noscrollWidth = window.document.documentElement.clientWidth
    const divOffsetLeft = textRef.current.offsetLeft
    const scrollTop =
      document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    let y
    switch (placement) {
      case 'top':
        y = textRef.current.offsetTop - scrollTop - 76
        break
      default:
        y = textRef.current.offsetTop - scrollTop + textRef.current.clientHeight + 10
        break
    }

    setPosition({
      x: divOffsetLeft,
      y,
      width: textRef.current.clientWidth,
      winWidth: noscrollWidth,
    })
  }

  return (
    <StyledContainer {...restProps}>
      {getDevice() === 'pc' && trigger === 'hover' && (
        <StyledEvent ref={textRef} onMouseOver={onHover} onMouseOut={() => selectedBlock(null)}>
          {children}
        </StyledEvent>
      )}
      {(getDevice() === 'mobile' || trigger === 'click') && (
        <StyledEvent ref={textRef} onClick={onHover}>
          {children}
        </StyledEvent>
      )}

      {block === index && (
        <StyledTooltip
          beforeleft={tipsLeft(position)[1]}
          placement={placement}
          style={{
            top: `${position.y}px`,
            left: `${tipsLeft(position)[0]}px`,
          }}
        >
          {content}
        </StyledTooltip>
      )}
    </StyledContainer>
  )
}

Tips.defaultProps = {
  trigger: 'hover',
  content: '',
  index: 0,
  block: '',
  selectedBlock: () => {},
  placement: 'bottom',
}

Tips.propTypes = {
  trigger: PropTypes.string,
  content: PropTypes.node,
  index: PropTypes.number,
  block: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedBlock: PropTypes.func,
  placement: PropTypes.string,
}

export default Tips

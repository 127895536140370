import {
  UPTIME,
  INCIDENT,
  CORE_SERVICE,
  DEV_PORTAL,
  INCIDENTS_HISTORY,
  LATENCY,
  INFRA_MEANTIME,
  INFRA_BASIC,
  MAINTENANCES,
} from './reducer'

export const fetchIncident = data => ({
  type: INCIDENT,
  data,
})

export const fetchCoreService = data => ({
  type: CORE_SERVICE,
  data,
})

export const fetchDevPortal = data => ({
  type: DEV_PORTAL,
  data,
})

export const fetchUptime = data => ({
  type: UPTIME,
  data,
})

export const fetchIncidentsHistory = data => ({
  type: INCIDENTS_HISTORY,
  data,
})

export const fetchLatency = data => ({
  type: LATENCY,
  data,
})

export const fetchInfraMeantime = data => ({
  type: INFRA_MEANTIME,
  data,
})

export const fetchInfraBasic = data => ({
  type: INFRA_BASIC,
  data,
})

export const fetchMaintenances = data => ({
  type: MAINTENANCES,
  data,
})

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Logo from 'assets/Logo'
import LogoPng from 'assets/Logo.png'
import { maxWidth, minWidth } from 'constants/options'

const StyledContainer = styled.div`
  background: #2f2f2f;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
`

const StyledCenterContent = styled.div`
  display: flex;
  align-items: center;
  width: ${maxWidth}px;
  height: 60px;
  margin: 0 auto;
  @media (min-width: ${minWidth}px) and (max-width: ${maxWidth + 20}px) {
    width: ${minWidth}px;
  }
  @media (max-width: ${minWidth + 20}px) {
    width: 100%;
    padding: 0 15px;
  }
`

const StyledLogo = styled(Logo)``

const StyledVerticalLine = styled.span`
  margin-left: 8.5px;
  margin-right: 8.5px;
  line-height: 25px;
  color: #ccc;
  font-size: 16px;
`

const StyledPageName = styled(Link)`
  font-size: 18px;
  color: #cccccc;
  line-height: 25px;
`

function Header() {
  return (
    <StyledContainer>
      <StyledCenterContent>
        <StyledLogo style={{ display: 'none' }} />
        <a href="https://pages.github.azc.ext.hp.com/hp-business-platform/home.io/" target="blank">
          <img
            src={LogoPng}
            alt="logo"
            style={{
              width: '118px',
              marginLeft: '-8px',
            }}
          />
        </a>
        <StyledVerticalLine>/</StyledVerticalLine>
        <StyledPageName to="/">Status</StyledPageName>
      </StyledCenterContent>
    </StyledContainer>
  )
}

export default Header

import React, { useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { map, path, split, join, toLower } from 'ramda'

import Title from 'components/Shared/Title'
import BgContainer from 'components/Shared/BgContainer'
import Context from 'hooks/contextManage'

const StyledContainer = styled.div``

const StyledDate = styled.span`
  margin-right: 4px;
`

const StyledDateNum = styled.span`
  font-size: 20px;
  margin-right: 3px;
  font-weight: 600;
`

const viewDate = (num, unit) => (
  <StyledDate>
    <StyledDateNum>{num}</StyledDateNum>
    <span>{unit}</span>
  </StyledDate>
)
function Meantime({ className, style }) {
  const { state } = useContext(Context)
  const data = path(['meanTime'], state)

  return map(i => {
    const { days, hours, minutes, seconds } = i
    return (
      <StyledContainer
        key={i.id || i.title}
        className={className}
        style={{ marginBottom: '48px', ...style }}
      >
        {i.title && (
          <Title
            name={i.title}
            rightText={i.relativeTimeRange}
            testId={toLower(join('_', split(' ', i.title)))}
          />
        )}
        <BgContainer loading={!i.title}>
          {i.title && !days && !hours && !minutes && !seconds ? viewDate(0, 's') : ''}
          {days && days > 0 ? viewDate(days, 'Days') : ''}
          {hours && hours > 0 ? viewDate(hours, 'h') : ''}
          {minutes && minutes > 0 ? viewDate(minutes, 'm') : ''}
          {seconds && seconds > 0 ? viewDate(seconds, 's') : ''}
        </BgContainer>
      </StyledContainer>
    )
  }, path(['meanTime'], data) || [])
}

Meantime.defaultProps = {
  style: {},
}

Meantime.propTypes = {
  // data: PropTypes.shape({
  //   meanTime: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       name: PropTypes.string,
  //       color: PropTypes.string,
  //     }),
  //   ),
  // }).isRequired,
  style: PropTypes.shape({}),
}

export default Meantime

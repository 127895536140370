import { split, map } from 'ramda'

const REGION = window.REACT_APP_SHELL_REGION || process.env.REACT_APP_SHELL_REGION

export const incident = [
  { name: 'Critical incident', level: 'INCIDENT_LEVEL_CRITICAL', color: '#FF3347' },
  { name: 'Major incident', level: 'INCIDENT_LEVEL_MAJOR', color: '#FFAD3D' },
  { name: 'Minor incident', level: 'INCIDENT_LEVEL_MINOR', color: '#FFCE1F' },
  { name: 'None incident', level: 'INCIDENT_LEVEL_NONE', color: '#00C259' },
  { name: 'Common maintenance', level: 'INCIDENT_LEVEL_COMM', color: '#182429' },
]

export const maxWidth = 840
export const minWidth = 808

export const regions = REGION
  ? map(i => {
      const value = split('=', i)
      return { label: value[0], value: value[1] }
    }, split('&', REGION))
  : []

// [
//   { label: 'US', value: 'us-west-2' },
//   { label: 'EU', value: 'eu-central-1' },
//   { label: 'CN', value: 'cn-northwest-1' },
// ]

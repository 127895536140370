import React from 'react'
import PropTypes from 'prop-types'

const Triangle = ({ width, height, fill, className, ...restProps }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path d="M6 5L-4.37114e-07 2.62268e-07L0 10L6 5Z" fill="#C4C4C4" />
  </svg>
)

Triangle.defaultProps = {
  width: '6',
  height: '10',
  fill: '#00C259',
}
Triangle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
}

export default Triangle

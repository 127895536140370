import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { map } from 'ramda'
import { Button, Select } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

const StyledContainer = styled.div`
  display: flex;
`

const StyledButton = styled(Button)`
  height: 32px;
  border-right: 0;
`

const times = [
  {
    key: 0,
    name: 'off',
  },
  {
    key: 30000,
    name: '30s',
  },
  {
    key: 60000,
    name: '1m',
  },
  {
    key: 300000,
    name: '5m',
  },
  {
    key: 900000,
    name: '15m',
  },
  {
    key: 1800000,
    name: '30m',
  },
  {
    key: 3600000,
    name: '1h',
  },
]

const { Option } = Select

function SettingTime({ className, style, loading, setDuration, refresh }) {
  const handleClick = e => {
    setDuration(parseInt(e, 10))
  }
  return (
    <StyledContainer className={className} style={style}>
      <StyledButton
        icon={<SyncOutlined spin={loading} />}
        loading={loading}
        onClick={() => {
          refresh()
        }}
      />
      <Select defaultValue="1m" style={{ width: 70 }} onChange={handleClick}>
        {map(
          i => (
            <Option key={i.key} value={i.key}>
              {i.name}
            </Option>
          ),
          times,
        )}
      </Select>
    </StyledContainer>
  )
}

SettingTime.defaultProps = {
  style: {},
  setDuration: () => {},
  loading: false,
  refresh: () => {},
}

SettingTime.propTypes = {
  style: PropTypes.shape({}),
  setDuration: PropTypes.func,
  loading: PropTypes.bool,
  refresh: PropTypes.func,
}

export default SettingTime

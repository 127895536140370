import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import Guide from './Status/Guide'
import Pasts from './Status/Pasts'

import Header from 'components/Header'
import Footer from 'components/Footer'

function Root() {
  return (
    <div>
      <Router history={createBrowserHistory()}>
        <Header />
        <Switch>
          <Route exact path="/" component={Guide} />
          <Route path="/pasts" component={Pasts} />
          <Redirect exact from="*" to="/" />
        </Switch>
        <Footer />
      </Router>
    </div>
  )
}

Root.defaultProps = {}

Root.propTypes = {}

export default Root

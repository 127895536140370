import React, { useContext } from 'react'
import styled from 'styled-components'
// import PropTypes from 'prop-types'
import { map, find, propEq, toLower, join, split, path } from 'ramda'
import moment from 'moment-timezone'
import { Tag } from 'antd'

import { incident, regions } from 'constants/options'
import Context from 'hooks/contextManage'

const StyledContainer = styled.div`
  background: #ffffff;
  border-radius: 3px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 2px 5px rgba(42, 59, 66, 0.2);
`

const StyledTop = styled.div`
  border-top: 3px solid ${props => props.color || '#dae2e5'};
  border-bottom: 1px solid #dae2e5;
  height: 48px;
  line-height: 43px;
`

const StyledName = styled.span`
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
  color: #435761;
`

const StyledStatusList = styled.div`
  padding-left: 20px;
  margin-top: 20px;
`

const StyledStatusName = styled.span`
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #435761;
`

const StyledDescription = styled.span`
  font-size: 14px;
  color: #435761;
`

const StyledDate = styled.div`
  font-size: 12px;
  color: #748e99;
`

function Incident() {
  const { state } = useContext(Context)
  const data = path(['incident', 'incidents'], state) || []

  return map(i => {
    const findColor = find(propEq('level', i.level))(incident) || {}

    return (
      <StyledContainer key={i.id}>
        <StyledTop color={findColor.color}>
          <StyledName testId={toLower(join('_', split(' ', findColor.name)))}>
            {i.level === 'INCIDENT_LEVEL_COMM' ? i.title : findColor.name}
            <Tag color="blue" style={{ marginLeft: '8px', verticalAlign: '3px' }}>
              {find(propEq('value', i.region))(regions).label}
            </Tag>
          </StyledName>
        </StyledTop>
        <div>
          {map(
            info => (
              <StyledStatusList key={info.id}>
                <div style={{ paddingRight: '10px' }}>
                  <StyledStatusName>{info.title}</StyledStatusName>
                  <StyledDescription>{info.detailMsg}</StyledDescription>
                </div>
                <StyledDate>
                  {info.timestamp || moment(info.timestamp).utc().format('MMMM Do YYYY, H:mm z')}
                  {` ${info.timezone}`}
                </StyledDate>
              </StyledStatusList>
            ),
            i.incidentInfos || [],
          )}
        </div>
      </StyledContainer>
    )
  }, data)
}

Incident.propTypes = {
  // data: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     date: PropTypes.string,
  //     color: PropTypes.string,
  //   }),
  // ).isRequired,
}

export default Incident

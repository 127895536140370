import Helper from 'react-data-helper'

const { keysToCamel, keysToSnack, camelToSnack, objToQuery } = Helper

export const requestOptionsCreator = token => (method, body, pub) => {
  const headers = {
    'content-type': 'application/json',
  }
  if (!pub) {
    headers.authorization = `Bearer ${token}`
  }
  return {
    method,
    body: body ? JSON.stringify(body) : null,
    headers,
  }
}

export const requestCreator = (apiBase, requestOptions) => async ({
  url,
  queryParams,
  body,
  method,
}) => {
  const options = requestOptions(method || 'GET', keysToSnack(body))
  const query =
    queryParams && Object.keys(queryParams).filter(k => queryParams[k] !== null).length > 0
      ? `?${objToQuery(camelToSnack, queryParams)}`
      : ''
  try {
    const resp = await fetch(
      url.startsWith('http') ? `${url}${query}` : `${apiBase}${url}${query}`,
      options,
    )

    let data = {}
    try {
      data = keysToCamel(await resp.json())
    } catch (e) {
      // just ignore this error
    }

    if (resp.status >= 400) {
      // eslint-disable-next-line no-throw-literal
      throw { ...data, status: resp.status }
    }
    return data
  } catch (error) {
    throw keysToCamel(error)
  }
}

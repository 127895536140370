/* eslint-disable max-lines */
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { path } from 'ramda'
import queryString from 'query-string'

import {
  StyledContainer,
  StyledContent,
  StyledSub,
  StyledButton,
  StyledMoreBtn,
  StyledLink,
  StyledRegions,
} from 'components/Status/GuideHelper'
import { regions } from 'constants/options'
import SubscribeModal from 'components/Status/SubscribeModal'
import Incident from 'components/Status/Incident'
import ViewStatus from 'components/Status/ViewStatus'
import Core from 'components/Status/Core'
import DevPortal from 'components/Status/DevPortal'
import UptimeChart from 'components/Status/UptimeChart'
import Meantime from 'components/Status/Meantime'
import CoreServiceApis from 'components/Status/CoreServiceApis'
import PastIncidents from 'components/Status/PastIncidents'
import SettingTime from 'components/Status/SettingTime'
import RegionButton from 'components/Shared/RegionButton'
import Context from 'hooks/contextManage'
import {
  fetchUptime,
  fetchIncident,
  fetchIncidentsHistory,
  fetchInfraBasic,
  fetchLatency,
  fetchInfraMeantime,
  fetchCoreService,
  fetchDevPortal,
} from 'hooks/action'
import * as api from 'api'

message.config({
  maxCount: 1,
})

const us = path([0, 'value'], regions)
function Status() {
  const history = useHistory()
  const { state, dispatch } = useContext(Context)
  const [duration, setDuration] = useState(60000)
  const [isAutocall, toggleAutocall] = useState(true)
  const [currentLoading, toggleCurrentLoading] = useState(false)
  const [latencyLoading, toggleLatencyLoading] = useState(false)
  const [subLoading, toggleSubLoading] = useState(false)
  const [visible, toggleVisible] = useState(false)
  const [coreRegion, setCoreRegion] = useState(us)
  const [infraRegion, setInfraRegion] = useState(us)
  const [timeRegion, setTimeRegion] = useState(us)
  // const [pastIncidentRegion, setPastIncidentRegion] = useState(us)
  const intervalRef = useRef()

  const getCoreService = useCallback(async () => {
    try {
      const result = await api.fetchCoreService({ region: coreRegion })
      dispatch(fetchCoreService(result))
    } catch (e) {
      message.error(e.message)
    }
  }, [coreRegion, dispatch])

  const getDevPortal = useCallback(async () => {
    try {
      const result = await api.fetchDevPortal()
      dispatch(fetchDevPortal(result))
    } catch (e) {
      message.error(e.message)
    }
  }, [dispatch])

  const getIncidents = useCallback(async () => {
    toggleCurrentLoading(true)
    try {
      const result = await api.fetchIncidents()
      toggleCurrentLoading(false)
      dispatch(fetchIncident(result))
    } catch (e) {
      toggleCurrentLoading(false)
      message.error(e.message)
    }
  }, [dispatch])

  const getIncidentsHistory = useCallback(async () => {
    try {
      const result = await api.fetchIncidentsHistory()
      dispatch(fetchIncidentsHistory(result))
    } catch (e) {
      message.error(e.message)
    }
  }, [dispatch])

  const getInfraBasic = useCallback(async () => {
    try {
      const result = await api.fetchInfraBasic({ region: infraRegion })
      dispatch(fetchInfraBasic(result))
    } catch (e) {
      message.error(e.message)
    }
  }, [dispatch, infraRegion])

  const getLatency = useCallback(async () => {
    try {
      toggleLatencyLoading(true)
      const result = await api.fetchLatency({ region: timeRegion })
      dispatch(fetchLatency(result))
      toggleLatencyLoading(false)
    } catch (e) {
      message.error(e.message)
      toggleLatencyLoading(false)
    }
  }, [dispatch, timeRegion])

  const getMeantime = useCallback(async () => {
    try {
      const result = await api.fetchInfraMeantime({ region: timeRegion })
      dispatch(fetchInfraMeantime(result))
    } catch (e) {
      message.error(e.message)
    }
  }, [dispatch, timeRegion])

  const getInfraUptime = useCallback(async () => {
    try {
      const result = await api.fetchInfraUptime({ region: timeRegion })
      dispatch(fetchUptime(result))
    } catch (e) {
      message.error(e.message)
    }
  }, [dispatch, timeRegion])

  useEffect(() => {
    return () => {
      toggleLatencyLoading(false)
      toggleCurrentLoading(false)
    }
  }, [])

  const confirm = async value => {
    try {
      const result = await api.subscribeConfirm(value)
      if (result.success) {
        message.success(result.message)
      }
      if (!result.error) {
        message.success(result.message)
      }
    } catch (e) {
      message.error(e.message)
    }
  }

  const unsubscribe = async value => {
    try {
      const result = await api.unsubscribe(value)
      if (result.success) {
        message.success(result.message)
      }
      if (!result.success) {
        message.error(result.message)
      }
    } catch (e) {
      message.error(e.message)
    }
  }

  useEffect(() => {
    const { action, token } = queryString.parse(history.location.search)
    if (action === 'confirm') {
      confirm({ token })
    }
    if (action === 'unsubscribe') {
      unsubscribe({ token })
    }
  }, [history])

  const getAllApi = useCallback(() => {
    getIncidents()
    getDevPortal()
    getCoreService()
    getInfraUptime()
    getIncidentsHistory()
    getInfraBasic()
    getLatency()
    getMeantime()
  }, [
    getIncidents,
    getDevPortal,
    getInfraUptime,
    getIncidentsHistory,
    getInfraBasic,
    getLatency,
    getMeantime,
    getCoreService,
  ])

  useEffect(() => {
    getIncidents()
    getDevPortal()
  }, [getIncidents, getDevPortal])

  useEffect(() => {
    getInfraBasic()
  }, [getInfraBasic])

  useEffect(() => {
    getCoreService()
  }, [getCoreService])

  useEffect(() => {
    getIncidentsHistory()
  }, [getIncidentsHistory])

  useEffect(() => {
    getInfraUptime()
    getLatency()
    getMeantime()
  }, [getInfraUptime, getLatency, getMeantime])

  useEffect(() => {
    const handle = setInterval(() => {
      getAllApi()
    }, duration)
    intervalRef.current = handle
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [getAllApi, duration])

  const handleCancel = () => {
    toggleVisible(false)
  }

  const handleOk = async value => {
    try {
      toggleSubLoading(true)
      const result = await api.subscribe({ email: encodeURIComponent(value.email) })
      message.success(result.message)
      toggleSubLoading(false)
      toggleVisible(false)
    } catch (e) {
      message.error(e.message)
      toggleSubLoading(false)
    }
  }

  const historyCount = parseInt(
    path(['pastIncidents', 'pagination', 'totalItemCount'], state) || 0,
    10,
  )

  return (
    <StyledContainer>
      <StyledContent>
        <StyledSub>
          <SettingTime
            style={{ display: 'none' }}
            refresh={() => {
              getAllApi()
            }}
            loading={currentLoading}
            setDuration={num => {
              if (num) {
                toggleAutocall(true)
                if (!isAutocall) {
                  const handle = setInterval(() => {
                    getAllApi()
                  }, num)
                  intervalRef.current = handle
                } else {
                  setDuration(num)
                }
              } else {
                toggleAutocall(false)
                clearInterval(intervalRef.current)
              }
            }}
          />
          <StyledButton
            type="primary"
            data-auid="auid_sub_btn"
            onClick={() => {
              toggleVisible(true)
            }}
          >
            Subscribe
          </StyledButton>
        </StyledSub>
        <Incident />
        <Core setCoreRegion={setCoreRegion} />
        <ViewStatus setInfraRegion={setInfraRegion} />
        <DevPortal />
        <StyledRegions>
          <RegionButton
            componentName="time_latency"
            onSwitch={region => {
              setTimeRegion(region)
            }}
          />
          <div style={{ padding: '20px 20px' }}>
            <UptimeChart style={{ marginBottom: '48px' }} />
            <Meantime />
            <CoreServiceApis loading={latencyLoading} />
          </div>
        </StyledRegions>

        <StyledRegions style={{ marginBottom: '30px' }}>
          <div>
            <PastIncidents
              data={state.pastIncidents}
              isViewAll={false}
              // setPastIncidentRegion={setPastIncidentRegion}
              style={{ padding: '0 20px 20px' }}
            />
            {historyCount > 0 && (
              <StyledMoreBtn>
                <StyledLink to="/pasts" data-auid="auid_status_history_btn">
                  More past incident
                </StyledLink>
              </StyledMoreBtn>
            )}
          </div>
        </StyledRegions>
      </StyledContent>
      <SubscribeModal
        visible={visible}
        loading={subLoading}
        onCancel={handleCancel}
        onFinish={handleOk}
      />
    </StyledContainer>
  )
}

Status.propTypes = {}

export default Status
